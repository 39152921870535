import React, { useContext } from "react";
import { Link } from "react-router-dom";
import get from "lodash/get";
import { PATHS, USER_TYPES } from "utils/constants";
import { StoreContext } from "shared/store";
import NotFoundImage from "shared/assets/images/404.png";

const NotFound = () => {
  const { user } = useContext(StoreContext);
  const ROOT_PATH = {
    [USER_TYPES.ADMIN]: PATHS.ADMIN.HOME,
    [USER_TYPES.OPERATOR]: PATHS.OPERATOR.HOME,
    [USER_TYPES.PUBLIC]: PATHS.PUBLIC.AUTH.SIGN_IN,
  };
  return (
    <div className="not-found__container">
      <div className="not-found__container--body">
        <img src={NotFoundImage} alt="" />
        <Link to={ROOT_PATH[get(user, "meta.type", USER_TYPES.PUBLIC)]} className="btn--back">
          Go Back
        </Link>
      </div>
    </div>
  );
};

export default {
  path: "/",
  exact: false,
  component: NotFound,
};
