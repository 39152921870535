import SingIn from "./SignIn";
import ForgotPassword from "./ForgotPassword";
import CodeVerification from "./CodeVerification";
import PasswordReset from "./PasswordReset";
import { PATHS } from "utils/constants";

export default [
  {
    path: PATHS.PUBLIC.AUTH.SIGN_IN,
    exact: true,
    component: SingIn
  },
  {
    path: PATHS.PUBLIC.AUTH.FORGOT_PASSWORD,
    exact: true,
    component: ForgotPassword
  },
  {
    path: PATHS.PUBLIC.AUTH.PASSCODE,
    exact: true,
    component: CodeVerification
  },
  {
    path: PATHS.PUBLIC.AUTH.RESET_PASSWORD,
    exact: true,
    component: PasswordReset
  }
];
