import axios from "axios";
import { idUrl } from "../../config";
import { API_PATHS } from "../constants";
import fetcher from "../fetcher";

const singInWithLink = payload =>
  axios.post(`${idUrl}${API_PATHS.AUTH.LINK_SIGN_IN}`, payload);
const singIn = payload =>
  axios.post(`${idUrl}${API_PATHS.AUTH.SIGN_IN}`, payload);
const forgotPassword = payload =>
  axios.post(`${idUrl}${API_PATHS.AUTH.FORGOT_PASSWORD}`, payload);
const resetPassword = payload =>
  fetcher.put({ url: `${idUrl}${API_PATHS.AUTH.RESET_PASSWORD}`, payload });
const updateEmail = payload =>
  fetcher.put({
    url: `${idUrl}${API_PATHS.USERS}/${payload.id}`,
    payload,
  });

export default {
  singInWithLink,
  singIn,
  forgotPassword,
  resetPassword,
  updateEmail,
};
