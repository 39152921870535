import { mutate } from 'swr';
import actions from '../../actions';
import { API_PATHS } from '../../../utils/constants';

export default {
  [actions.recording.created]: (payload) =>
    mutate(`${API_PATHS.RECODINGS.INDEX}/${payload.session.id}`, payload),
  [actions.recording.stoped]: (payload) =>
    mutate(`${API_PATHS.RECODINGS.INDEX}/${payload.session.id}`, null),
};
