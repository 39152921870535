import { OpenVidu } from "openvidu-browser";
import { useEffect, useState } from "react";
import { takeRight, sum } from "lodash";
import axios from "axios";
import {
  DefaulOpenviduConfig,
  NODE_ENVS,
  RESOLUTIONS,
} from "../../utils/constants";
import useNetwork from "./useNetwork";

if (process.env.NODE_ENV === NODE_ENVS.PROD)
  OpenVidu.prototype.enableProdMode();

const useIM = ({ token }) => {
  const OV = new OpenVidu();
  OV.enableProdMode();
  const [session] = useState(OV.initSession());
  const network = useNetwork();
  const [subscribers, setSubscribers] = useState([]);
  const [publisher, setPublisher] = useState();
  const [errorCount, setErrorCount] = useState([]);
  const [videoIsDisabled, setVideoIsDisabled] = useState(false);
  const [mediaConfig, setMediaConfig] = useState({
    publishAudio: true,
    publishVideo: true,
  });

  const toggleAudio = () => {
    publisher.publishAudio(!mediaConfig.publishAudio);
    setMediaConfig((mediaConfig) => ({
      ...mediaConfig,
      publishAudio: !mediaConfig.publishAudio,
    }));
  };

  const toggleVideo = () => {
    publisher.publishVideo(!mediaConfig.publishVideo);
    setMediaConfig((mediaConfig) => ({
      ...mediaConfig,
      publishVideo: !mediaConfig.publishVideo,
    }));
  };

  const disconnect = () => {
    return new Promise((resolve) => {
      try {
        session.disconnect();
        return resolve();
      } catch {
        return resolve(null);
      }
    });
  };

  useEffect(() => {
    session.on("streamCreated", (event) => {
      return setSubscribers((subscribers) => {
        return [...subscribers, session.subscribe(event.stream)];
      });
    });
    session.on("streamDestroyed", (event) => {
      return setSubscribers((subscribers) => {
        return subscribers.filter(
          (subscriber) => subscriber.stream.streamId !== event.stream.streamId
        );
      });
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      await session.connect(token, { clientData: "test" });
      const publisher = OV.initPublisher(undefined, {
        ...DefaulOpenviduConfig,
        ...mediaConfig,
        resolution: RESOLUTIONS[network],
      });
      session.publish(publisher);
      setPublisher(publisher);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const errorSum = sum(takeRight(errorCount, 10));
    const disableVideo = errorSum > 5 ? false : true;
    setVideoIsDisabled(!disableVideo);
    publisher &&
      publisher.publishVideo(
        disableVideo ? mediaConfig.publishVideo : disableVideo
      );
    // eslint-disable-next-line
  }, [errorCount]);

  useEffect(() => {
    const interval = setInterval(async () => {
      axios
        .get(`https://${process.env.REACT_APP_B2B_URL}/health`, {
          timeout: 2000,
        })
        .then(() =>
          setErrorCount((errorCount) => [...takeRight(errorCount, 9), -1])
        )
        .catch(() => {
          setErrorCount((errorCount) => [...takeRight(errorCount, 9), 1]);
        });
    }, 2000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  return {
    publisher,
    toggleAudio,
    toggleVideo,
    disconnect,
    subscribers,
    videoIsDisabled,
    ...mediaConfig,
  };
};

export default useIM;
