import React from "react";
import { Modal as ModalComponent } from "antd";
import propTypes from "prop-types";

const Modal = ({ visible, title, message }) => (
  <ModalComponent
    title={title}
    visible={visible}
    footer={null}
    closable={false}
  >
    <p>{message}</p>
  </ModalComponent>
);
Modal.propTypes = {
  visible: propTypes.bool,
  message: propTypes.string,
  title: propTypes.string
};

Modal.defaultProps = {
  visible: false,
  message: "",
  title: ""
};
export default Modal;
