import Auth from "@bit/taillislabs.pega.auth";
import { Layout } from "antd";
import { Formik } from "formik";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import { StoreContext } from "shared/store";
import authApi from "utils/api/auth";
import { AUTH_STYLE, PATHS, STORAGE_KEYS } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import { useMutation } from "utils/hooks";
import validation from "./validation";

const { Content } = Layout;

export default ({ history }) => {
  const { t } = useTranslation(NAME_SPACES.AUTH);
  const { source: {url} } = useContext(StoreContext);
  const accessToken = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN);
  const redirection = `${url}?access_token=${accessToken}`;
  const [resetPassword] = useMutation(authApi.resetPassword, {
    onSuccess: () => {
      window.location = redirection;
    },
  });
  const inputs = [{ name: "password", placeholder: "Nouveau mot de passe" }];
  return (
    <Layout className="layout">
      <Content className="layout--container auth">
        <div className="section--login">
          <Formik
            initialValues={{
              password: "",
            }}
            onSubmit={({ password }) => {
              resetPassword({
                password,
              });
            }}
            validationSchema={validation(
              t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
            )}
          >
            {({ isValid, handleSubmit, ...formProps }) => (
              <Auth
                title={"Saisir le nouveau mot de passe"}
                subtitle={"Veuillez insérer le nouveau mot de passe"}
                inputs={inputs.map((input) => ({
                  ...input,
                  ...bindInputProps({ name: input.name, ...formProps }),
                }))}
                submitText={"Valider"}
                onSubmit={handleSubmit}
                forgotPasswordText={t("SIGN_IN")}
                onPasswordForgot={() => history.push(PATHS.PUBLIC.AUTH.SIGN_IN)}
                style={AUTH_STYLE}
              />
            )}
          </Formik>
        </div>
      </Content>
    </Layout>
  );
};
