import axios from "axios";
import { idUrl } from "../../config";
import { STORAGE_KEYS } from "utils/constants";

export default () =>
  axios.create({
    baseURL: idUrl,
    headers: {
      common: {
        Authorization: `bearer ${window.localStorage.getItem(
          STORAGE_KEYS.ACCESS_TOKEN
        )}`,
      },
    },
  });
