/* eslint-disable */
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import "shared/locales/i18n";
import { StoreProvider } from "shared/store";
import App from "./App";
import "./index.scss";
import "./State";

const Component = () => (
  <StoreProvider>
      <HashRouter>
        <App />
      </HashRouter>
  </StoreProvider>
);

const render = () => {
  ReactDOM.render(<Component />, document.getElementById("root"));
};

render();

