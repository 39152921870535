export const NAME_SPACES = {
  AUTH: "auth",
  ERRORS: "errors",
  SIDER: "sider",
  APPOINTMENT: "appointment",
  IM: "im",
  HOME: "home",
  ENUMS: "enums",
  PREFERENCES: "preferences",
};
