export const USER_TYPES = {
  ADMIN: "admin",
  OPERATOR: "operator",
  GUEST: "guest",
  PUBLIC: "PUBLIC",
};

export const PATHS = {
  INDEX: "/",
  PROFILE: "/profile",
  ADMIN: {
    HOME: "/",
    APPOINTMENT: {
      LIST: "/appointments",
      CREATE: "/appointments/create",
      DETAIL: "/appointments/:id",
      DELETE: "/appointments/delete/:id",
      UPDATE: "/appointments/update/:id",
    },
    PREFERENCES: {
      INDEX: "/preferences",
    },
  },
  OPERATOR: {
    HOME: "/",
    APPOINTMENT: {
      LIST: "/appointments",
      CREATE: "/appointments/create",
      DETAIL: "/appointments/:id",
      DELETE: "/appointments/delete/:id",
      UPDATE: "/appointments/update/:id",
    },
    PREFERENCES: {
      INDEX: "/preferences",
    },
    NOTES: {
      CREATE: "/notes/create",
    },
    IM: {
      JOIN: "/call/:id",
      RECONNECT: "/reconnect/:id",
    },
    STATS: "/stats",
  },
  PUBLIC: {
    AUTH: {
      SIGN_IN: "/sign-in",
      FORGOT_PASSWORD: "/auth/password/forgot",
      RESET_PASSWORD: "/auth/password/reset/",
      PASSCODE: "/auth/password/passcode",
    },
    RESSOURCES: {
      INDEX: "/public",
      THANK_YOU: "/public/thank-you",
      IM: {
        JOIN: "/public/call/:id",
      },
    },
  },
};
export const PREFIX_WEBSITE = "https://app.souldan.com";
export const SOCKET_EVENTS = {
  REST_API_REPLY: "REST_API_REPLY",
};
export const ENCRYPT_SECRET_KEY_SLAT = "$2b$10$zkayYQ8IfnCXSu96WfyN.O";

export const PASSCODE_LENGTH = 6;
export const DefaulOpenviduConfig = {
  audioSource: undefined, // The source of audio. If undefined default microphone
  videoSource: undefined, // The source of video. If undefined default webcam
  resolution: "640x480", // The resolution of your video
  frameRate: 30, // The frame rate of your video
  insertMode: "APPEND", // How the video is inserted in the target element "video-container"
  mirror: false, // Whether to mirror your local video or not
};

export const STORAGE_KEYS = {
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
  SOURCE: "source",
};

export const API_PATHS = {
  AUTH: {
    LINK_SIGN_IN: "/auth/link/sign-in",
    SIGN_IN: "/auth/sign-in",
    FORGOT_PASSWORD: "/auth/password/forgot",
    RESET_PASSWORD: "/auth/password/reset",
    CHANGE_PASSWORD: "/auth/password/change",
  },
  APPOINTMENTS: "/appointments",
  APPOINTMENTS_STATES: "/appointments/statistics",
  NOTES: "/notes",
  PROSPECTS: "/prospects",
  OPERATORS: "/operators",
  USERS: "/users",
  TOKENS: "/tokens",
  SESSIONS: "/session",
  FILE: "/get-file",
  RECODINGS: {
    INDEX: "/recordings",
    STOP: "/recordings/stop",
  },
};

export const COLORS = {
  MAIN: "#172975",
  GRAY_D9: "#cacfd9",
  GRAY_F8: "#F0F2F8",
};

export const SESSION_STATUSES = {
  PENDING: "pending",
  STARTED: "started",
  ENDED: "ended",
};

export const RECODINGS_STATUSES = {
  STARTED: "STARTED",
  STOPED: "STOPED",
  PENDING: "PENDING",
};

export const MONTHS = [
  { label: "JANUARY", value: 1 },
  { label: "FEBRUARY", value: 2 },
  { label: "MARCH", value: 3 },
  { label: "AVRIL", value: 4 },
  { label: "MAY", value: 5 },
  { label: "JUNE", value: 6 },
  { label: "JULY", value: 7 },
  { label: "AUGUST", value: 8 },
  { label: "SEPTEMBER", value: 9 },
  { label: "OCTOBER", value: 10 },
  { label: "NOVEMBRE", value: 11 },
  { label: "DECEMBER", value: 12 },
];

export const NODE_ENVS = {
  DEV: "development",
  PROD: "production",
  TEST: "test",
};

export const NETWORK_QUALITIES = {
  LOW: "low",
  MEDIUM: "medium",
  HIGH: "high",
};

export const NETWORK_RANGES = [
  { name: NETWORK_QUALITIES.LOW, range: { start: 0, end: 1 } },
  { name: NETWORK_QUALITIES.MEDIUM, range: { start: 1, end: 5 } },
  { name: NETWORK_QUALITIES.HIGH, range: { start: 5, end: Infinity } },
];

export const RESOLUTIONS = {
  [NETWORK_QUALITIES.LOW]: "320x240",
  [NETWORK_QUALITIES.MEDIUM]: "640x480",
  [NETWORK_QUALITIES.MEDIUM]: "1280x720",
};

export const BROWSERS = {
  CHROME: "chrome",
};

export const OPERATING_SYSTEMS = {
  MAC_OS: "mac os",
  IOS: "ios",
};

export const AUTH_STYLE = {
  button: {
    backgroundColor: "#112B75",
  },
  forgotPassword: {
    color: "#222",
  },
  subtitle: {
    color: "#777",
  },
};

export const DATE_FORMAT = "YYYY-MM-DD HH:mm";

export const REGEX = {
  PHONE:
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
};

export const DEFAULT_ORGANIZATION_BACKGROUND =
  "default_organization_background.jpeg";
