import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { STORAGE_KEYS } from "utils/constants";

export const Context = React.createContext("store");
export const { Consumer } = Context;

export const Provider = ({ children }) => {

    const [token, setTokens] = useState({
      accessToken: "",
      refreshToken: "",
    });
  const [source, setSource]= useState({});
  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, token.accessToken);
    localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, token.refreshToken);
  }, [token]);
  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.SOURCE, JSON.stringify(source));
  }, [source]);
  return (
    <Context.Provider
      value={{
        ...token,
        source,
        setTokens,
        setSource,
      }}
    >
      {children}
    </Context.Provider>
  );
};
Provider.propTypes = {
  children: PropTypes.object.isRequired,
};
