import Auth from "@bit/taillislabs.pega.auth";
import { Layout, notification } from "antd";
import { Formik } from "formik";
import qs from "qs";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { NAME_SPACES } from "shared/locales/constants";
import { StoreContext } from "shared/store";
import authApi from "utils/api/auth";
import { AUTH_STYLE, PATHS } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import { useMutation } from "utils/hooks";
import validation from "./validation";

const { Content } = Layout;

export default ({ history }) => {
  const location = useLocation();
  const { t } = useTranslation(NAME_SPACES.AUTH);
  const { source, setSource } = useContext(StoreContext);
  useEffect(() => {
    const { source: parsedSource } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
     parsedSource && setSource(parsedSource);
  },[location.search, setSource]);

  const [signIn] = useMutation(authApi.singIn, {
    onSuccess: ({
      data: {
        accessToken,
        source: { url },
      },
    }) => {
      window.location = `${url}?access_token=${accessToken}`;
    },
    onError: () => {
      notification.open({
        message: t("INVALIDE_CREDENTIALS"),
      });
    },
  });
  const inputs = [
    { name: "username", placeholder: "Nom d'utilisateur" },
    { name: "password", placeholder: "Mot de passe", type: "password" },
  ];
  return (
    <Layout className="layout">
      <Content className="layout--container auth">
        <div className="section--login">
          <Formik
            initialValues={{
              username: "",
              password: "",
            }}
            onSubmit={(data) =>
              signIn({ user: data, source })
            }
            validationSchema={validation(
              t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
            )}
          >
            {({ isValid, handleSubmit, ...formProps }) => (
              <Auth
                title={"Bonjour"}
                subtitle={"Content de vous revoir"}
                inputs={inputs.map((input) => ({
                  ...input,
                  ...bindInputProps({ name: input.name, ...formProps }),
                }))}
                submitText={"Me connecter"}
                forgotPasswordText={"Mot de passe oublié?"}
                onSubmit={handleSubmit}
                onPasswordForgot={() =>
                  history.push(PATHS.PUBLIC.AUTH.FORGOT_PASSWORD)
                }
                style={AUTH_STYLE}
              />
            )}
          </Formik>
        </div>
      </Content>
    </Layout>
  );
};
