export default {
  token: {
    created: "token.created",
  },
  recording: {
    created: "recording.created",
    stoped: "recording.stoped",
  },
  file: {
    created: "file.created",
  },
};
